<template>
  <Modal event-alert-modal @close="close">
    <div class="image">
      <TwinKleEffect />
      <img v-que="{animation:'tada', delay:0, duration: 1000}" src="/img/pages/loldocup/event/ico-warning.svg" alt="warning">
    </div>
    <div class="text-holder">
      <h2 v-html="message" />
    </div>
    <div class="btn-group">
      <button @click="resolve">{{ btnText }}</button>
    </div>
  </Modal>
</template>

<script>
import Modal from 'shared/components/common/Modal.vue';
import que from '@/directives/que';
import TwinKleEffect from '@/views/components/loldocup/effect/TwinKleEffect.vue';

export default {
  name: 'EventAlertModal',
  directives: { que },
  components: { TwinKleEffect, Modal },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    message() {
      return this.options?.message || '';
    },
    btnText() {
      return this.options?.button || '확인';
    },
  },
  methods: {
    close() {
      this.$emit('close', false);
    },
    resolve() {
      this.$emit('resolve', false);
    },
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[event-alert-modal] { .tc;
  > .panel {
    &.large { .max-w(319); .w(90%);
      > .scroll-area { .p(0, 20); }
    }
    > .header { .pt(27);
      .close svg { .wh(31);
        .stroke-target { stroke-width: 2px; }
      }
    }
    .image { .rel; .wh(192, 94); .mh-c;
      [twinkle-effect] { .wh(100%, 94); transform: scale(0.8); }
      > img { .wh(11, 48); .mt(31); }
    }
    .text-holder { .mt(28); .mb(16);
      h2 { .fs(16); .mb(21);
        > small { .block; .w(202); .mh-c; .mt(4); .fs(12, 14); .c(#cc4dc8); .font-g; .medium; }
      }
    }
    .btn-group { .pb(18);
      button { .min-w(122); .h(36); .p(0, 20); .br(8); .fs(16); .c(#fff); .font-g; .bgc(#005eb8) }
    }
  }

  @media (@tl-up) {
    > .panel {
      &.large { .max-w(628); .w(100%);
        > .scroll-area { .p(0, 40); }
      }
      > .header { .pt(40);
        .close svg { .wh(40); }
      }
      .image { .wh(244, 129);
        [twinkle-effect] { .wh(100%, 129); transform: scale(1); }
        > img { .wh(17, 80); }
      }
      .text-holder { .mt(33); .mb(42);
        h2 { .fs(24, 31);
          > small { .w; .mt(2); .fs(16, 21);
            > br { .hide; }
          }
        }
      }
      .btn-group { .pb(30);
        button { .h(52); .p(0, 30); .fs(24); }
      }
    }
  }
}

</style>
