<template>
  <div application-event-visual class="visual">
    <div class="context">
      <h3 v-que="{animation: 'fadeInUp', delay: 10, duration: 500}" class="title">롤 도네이션 컵에 참여하고<br>좋아하는 스타의 애장품에 응모하세요!</h3>
      <twinkle-button v-que="{animation: 'fadeInUp', delay: 300, duration: 500}" class="btn-enter" theme="sky" @click="goApplicationApply">
        참가 신청 바로가기
      </twinkle-button>
    </div>
  </div>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import que from '@/directives/que';
import TwinkleButton from '@/views/components/loldocup/TwinkleButton.vue';

export default {
  name: 'ApplicationEventVisual',
  directives: { que },
  mixins: [gtag],
  components: { TwinkleButton },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    goApplicationApply() {
      this.trackEvent('GIFT_banner', 'click', `${this.upRouteGameId}`);
      this.$router.push('/b/lol?page=arena');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[application-event-visual] { .rel; .tc; .cover('@{do-event}/banner_m.jpg'); .no-repeat; .bg-xy(50%, 0);
  .context { .rel; .z(2); .wh(100%, 394); .pt(135);
    .title { .w(300); .mh-c; .fs(28, 33); .c(#fff); }
    .btn-enter { .wh(230, 36); .mt(28); .fs(14, 100%); .ls(-1); .p(0);
      .bg {
        svg {
          &:nth-of-type(1) {.lt(28, 2); .wh(12);}
          &:nth-of-type(2) {.lb(12, 4); .wh(6);}
          &:nth-of-type(3) {.rb(28, 4); .wh(9);}
        }
      }
    }
  }

  @media (@tl-up) {
    .bg-xy(50%, 0); .cover('@{do-event}/banner.jpg');
    .context { .rel; .z(2); .wh(100%, 480); .pt(209);
      .title { .w(100%); .mh-c; .fs(36, 46); .c(#fff); }
      .btn-enter { .wh(350, 52); .mt(32); .fs(24, 100%); .ls(-2);
        .bg {
          svg {
            &:nth-of-type(1) {.lt(52, 4); .wh(18);}
            &:nth-of-type(2) {.lb(42, 8); .wh(8);}
            &:nth-of-type(3) {.rb(54, 2); .wh(14);}
          }
        }
      }
    }
  }
}

</style>
