<template>
  <modal v-que="{delay:3000}" event-success-modal @close="close">
    <div class="image">
      <CongratulationEffect />
      <img v-que="{animation:'scaleInShake', delay:0, duration: 1000}" src="/img/pages/loldocup/event/ico-check.svg" alt="complete">
    </div>
    <div class="text-holder">
      <h2>애장품 응모가 완료되었습니다</h2>
    </div>
  </modal>
</template>

<script>
import Modal from 'shared/components/common/Modal.vue';
import que from '@/directives/que';
import CongratulationEffect from '@/views/components/loldocup/effect/CongratulationEffect.vue';

export default {
  name: 'EventSuccessModal',
  directives: { que },
  components: { CongratulationEffect, Modal },
  mounted() {
    setTimeout(() => {
      this.close();
    }, 3500);
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[event-success-modal] { .tc; visibility: visible !important; .o(1); transition: opacity .4s;
  &.que-done { .o(0); }
  .panel { overflow: visible;
    &.large { .max-w(319); .wh(90%, 258);
      > .scroll-area { overflow: visible; }
    }
    .header {
      .close { .hide;}
    }
    .image { .rel; .mb(24); .mt(55);
      [congratulation-effect] { transform: scale(0.55); }
      > img { .w(58); }
    }
    .text-holder {
      h2 { .fs(16, 21); .mb(40);}
    }
  }

  @media (@tl-up) {
    .panel {
      &.large { .max-w(628); .wh(100%, 400);}
      .image { .mt(103);
        [congratulation-effect] { transform: scale(1); }
        > img { .w; }
      }
      .text-holder {
        h2 { .fs(28, 36); .mb(40);}
      }
    }
  }
}

</style>
