<template>
  <div twinkle-effect>
    <svg class="tw-1" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.95967 17.672C7.69726 17.672 7.464 17.5005 7.36195 17.2356L5.77295 12.7787L4.5338 13.3864C4.30055 13.4955 4.00899 13.4488 3.81947 13.2462C3.62995 13.0436 3.58622 12.7475 3.68826 12.4826L4.16935 11.3294L0.408178 9.80217C0.160349 9.70866 0 9.45932 0 9.17882C0 8.89831 0.160349 8.64897 0.408178 8.53989L4.28598 6.91918L3.68826 5.50106C3.58622 5.25172 3.62995 4.94005 3.81947 4.75304C4.00899 4.55045 4.28596 4.5037 4.51921 4.61278L5.83124 5.22055L7.36195 0.451923C7.44942 0.171415 7.69726 0 7.95967 0C8.23665 0 8.46992 0.187005 8.55739 0.467512L10.0006 5.01797L11.3709 4.30111C11.6188 4.17644 11.9103 4.22319 12.0998 4.42578C12.2894 4.62837 12.3331 4.94004 12.2165 5.20496L11.4293 6.9036L15.4966 8.55547C15.7444 8.64897 15.9048 8.9139 15.9048 9.19441C15.9048 9.47491 15.7444 9.72425 15.4966 9.83334L11.473 11.4852L11.9104 12.5293C12.0124 12.7787 11.9687 13.0903 11.7791 13.2773C11.5896 13.4799 11.3126 13.5267 11.0794 13.4176L10.0735 12.9345L8.55739 17.2512C8.46992 17.5005 8.23665 17.672 7.95967 17.672Z" fill="#99EEEE" />
    </svg>
    <svg class="tw-2" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.95967 17.672C7.69726 17.672 7.464 17.5005 7.36195 17.2356L5.77295 12.7787L4.5338 13.3864C4.30055 13.4955 4.00899 13.4488 3.81947 13.2462C3.62995 13.0436 3.58622 12.7475 3.68826 12.4826L4.16935 11.3294L0.408178 9.80217C0.160349 9.70866 0 9.45932 0 9.17882C0 8.89831 0.160349 8.64897 0.408178 8.53989L4.28598 6.91918L3.68826 5.50106C3.58622 5.25172 3.62995 4.94005 3.81947 4.75304C4.00899 4.55045 4.28596 4.5037 4.51921 4.61278L5.83124 5.22055L7.36195 0.451923C7.44942 0.171415 7.69726 0 7.95967 0C8.23665 0 8.46992 0.187005 8.55739 0.467512L10.0006 5.01797L11.3709 4.30111C11.6188 4.17644 11.9103 4.22319 12.0998 4.42578C12.2894 4.62837 12.3331 4.94004 12.2165 5.20496L11.4293 6.9036L15.4966 8.55547C15.7444 8.64897 15.9048 8.9139 15.9048 9.19441C15.9048 9.47491 15.7444 9.72425 15.4966 9.83334L11.473 11.4852L11.9104 12.5293C12.0124 12.7787 11.9687 13.0903 11.7791 13.2773C11.5896 13.4799 11.3126 13.5267 11.0794 13.4176L10.0735 12.9345L8.55739 17.2512C8.46992 17.5005 8.23665 17.672 7.95967 17.672Z" fill="#EACFFF" />
    </svg>
    <svg class="tw-3" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.16041 5.1909C7.3894 3.45042 7.06878 0 7.06878 0C7.06878 0 6.74817 3.45042 4.97716 5.1909C3.22141 6.91611 0 7.06878 0 7.06878C0 7.06878 3.22141 7.22146 4.97716 8.94667C6.74817 10.6719 7.06878 14.1376 7.06878 14.1376C7.06878 14.1376 7.3894 10.6871 9.16041 8.96194C10.9314 7.23672 14.1376 7.08405 14.1376 7.08405C14.1376 7.08405 10.9314 6.91611 9.16041 5.1909Z" fill="#FFA0A0" />
    </svg>
    <svg class="tw-4" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.16041 5.1909C7.3894 3.45042 7.06878 0 7.06878 0C7.06878 0 6.74817 3.45042 4.97716 5.1909C3.22141 6.91611 0 7.06878 0 7.06878C0 7.06878 3.22141 7.22146 4.97716 8.94667C6.74817 10.6719 7.06878 14.1376 7.06878 14.1376C7.06878 14.1376 7.3894 10.6871 9.16041 8.96194C10.9314 7.23672 14.1376 7.08405 14.1376 7.08405C14.1376 7.08405 10.9314 6.91611 9.16041 5.1909Z" fill="#B9DDFF" />
    </svg>
    <svg class="tw-5" width="9" height="9" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.16041 5.1909C7.3894 3.45042 7.06878 0 7.06878 0C7.06878 0 6.74817 3.45042 4.97716 5.1909C3.22141 6.91611 0 7.06878 0 7.06878C0 7.06878 3.22141 7.22146 4.97716 8.94667C6.74817 10.6719 7.06878 14.1376 7.06878 14.1376C7.06878 14.1376 7.3894 10.6871 9.16041 8.96194C10.9314 7.23672 14.1376 7.08405 14.1376 7.08405C14.1376 7.08405 10.9314 6.91611 9.16041 5.1909Z" fill="#FFBEFD" />
    </svg>
    <svg class="tw-6" width="14" height="14" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.16041 5.1909C7.3894 3.45042 7.06878 0 7.06878 0C7.06878 0 6.74817 3.45042 4.97716 5.1909C3.22141 6.91611 0 7.06878 0 7.06878C0 7.06878 3.22141 7.22146 4.97716 8.94667C6.74817 10.6719 7.06878 14.1376 7.06878 14.1376C7.06878 14.1376 7.3894 10.6871 9.16041 8.96194C10.9314 7.23672 14.1376 7.08405 14.1376 7.08405C14.1376 7.08405 10.9314 6.91611 9.16041 5.1909Z" fill="#99EEEE" />
    </svg>
    <svg class="tw-7" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.16041 5.1909C7.3894 3.45042 7.06878 0 7.06878 0C7.06878 0 6.74817 3.45042 4.97716 5.1909C3.22141 6.91611 0 7.06878 0 7.06878C0 7.06878 3.22141 7.22146 4.97716 8.94667C6.74817 10.6719 7.06878 14.1376 7.06878 14.1376C7.06878 14.1376 7.3894 10.6871 9.16041 8.96194C10.9314 7.23672 14.1376 7.08405 14.1376 7.08405C14.1376 7.08405 10.9314 6.91611 9.16041 5.1909Z" fill="#B9DDFF" />
    </svg>
    <span class="circle cr-1" style="background-color:#ffc29f;"></span>
    <span class="circle cr-2" style="background-color:#ffe49f;"></span>
    <span class="circle cr-3" style="background-color:#80b2ff;"></span>
    <span class="circle cr-4" style="background-color:#ffe49f;"></span>
    <span class="circle cr-5" style="background-color:#a19fff;"></span>
    <div class="firework orange">
      <div v-for="n in 8" :key="`exp-${n}`" class="explosion"></div>
    </div>
    <div class="firework yellow">
      <div v-for="n in 8" :key="`exp-${n}`" class="explosion"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwinKleEffect',
};
</script>

<style lang="less" scoped>
@import '~@/less/proj.less';

[twinkle-effect] { .abs; .lt(0, 0); .r(0); .z(1); .wh(243, 129); .mh-c;
  .tw {
    &-1 { .abs; .lt(16, 0); .z(1); animation: star-1 3s ease-in-out infinite; }
    &-2 { .abs; .rb(4.15, 0); .z(1); animation: star-2 2s ease-in-out infinite; }
    &-3 { .abs; .lt(60, 8); .z(1); animation: star-3 4s ease-in-out infinite; }
    &-4 { .abs; .rt(54, 14); .z(1); animation: star-1 2s ease-in-out infinite; }
    &-5 { .abs; .rt(25, 57); .z(1); animation: star-3 6s ease-in-out infinite; }
    &-6 { .abs; .rb(7, 46); .z(1); animation: star-2 2s ease-in-out infinite; }
    &-7 { .abs; .lb(32, 51); .z(1); animation: star-3 3s ease-in-out infinite; }
  }
  .circle { .ib; .wh(7.5); .br(50%); animation: fade 3s ease-in-out infinite;
    &.cr {
      &-1 { .wh(3.5); .abs; .lt(18, 39); .z(1); animation-duration: 6s; }
      &-2 { .wh(6); .abs; .lb(0, 50); .z(1); animation-duration: 5s; }
      &-3 { .wh(8); .abs; .lt(35, 55); .z(1); animation-duration: 4s; }
      &-4 { .wh(4); .abs; .rb(20, 74); .z(1); animation-duration: 3s; }
      &-5 { .wh(4); .abs; .rt(0, 53); .z(1); animation-duration: 4.5s; }
    }
  }
  .firework { .abs; .z(1);
    .explosion { .crop; .abs; .lb(-2, 0); .wh(3, 20); .bgc(transform); transform-origin: 50% 20%;
      &:nth-child(1) { transform: rotate(0deg) translateY(-15px); }
      &:nth-child(2) { transform: rotate(45deg) translateY(-15px); }
      &:nth-child(3) { transform: rotate(90deg) translateY(-15px); }
      &:nth-child(4) { transform: rotate(135deg) translateY(-15px); }
      &:nth-child(5) { transform: rotate(180deg) translateY(-15px); }
      &:nth-child(6) { transform: rotate(225deg) translateY(-15px); }
      &:nth-child(7) { transform: rotate(270deg) translateY(-15px); }
      &:nth-child(8) { transform: rotate(315deg) translateY(-15px); }
      &::before { .cnt; .abs; .l(0); .r(0); .t(100%); .h(10); .bgc(#f5cf52); animation: explosion 2s ease-in-out infinite; }
    }
    &.orange { .lb(26, 6);
      .explosion {
        &::before { .bgc(#ffa78b); animation-delay: 1.5s; animation-duration: 2.2s; }
      }
    }
    &.yellow { .rt(21, 35);
      .explosion {
        &::before { .bgc(#ffe49f); animation-duration: 1.9s; }
      }
    }
  }

  @keyframes explosion {
    0% { .t(100%); }
    33%, 100% { .t(-50%); }
  }

  @keyframes star-1 {
    0% { transform: translateY(0); .o(1); }
    30% { transform: translateY(-6px); }
    60% { transform: translateY(-3px); .o(0); }
    100% { transform: translateY(-0px); .o(1); }
  }

  @keyframes star-2 {
    0% { transform: translateY(0); .o(1); }
    20% { transform: translateY(-4px); }
    50% { transform: translateY(-2px); .o(0); }
    70% { transform: translateY(-1px); .o(0.5); }
    100% { transform: translateY(-0px); .o(1); }
  }

  @keyframes star-3 {
    0% { transform: translateY(0); .o(1); }
    50% { transform: translateY(1px); }
    75% { transform: translateY(0px); .o(0); }
    100% { transform: translateY(0px); .o(1); }
  }

  @keyframes fade {
    0% { transform: scale(1); .o(1); }
    50% { transform: scale(0); .o(0); }
    100% { transform: scale(1); .o(1); }
  }
}
</style>
