<template>
  <button twinkle-button type="button" :class="[theme]" @click="$emit('click')">
    <span class="bg">
      <svg class="twinkle" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.48448 4.74919L7.48525 4.74998C8.27224 5.55764 9.3644 5.98997 10.238 6.22176C10.2627 6.22833 10.2873 6.23474 10.3118 6.241C10.2876 6.2471 10.2633 6.25334 10.2389 6.25973C9.36512 6.48814 8.27254 6.91704 7.48525 7.72501C6.69838 8.53255 6.24737 9.72457 5.992 10.6866C5.96415 10.7915 5.93852 10.8941 5.91495 10.9937C5.89103 10.8921 5.86498 10.7873 5.83663 10.6801C5.58135 9.71499 5.13048 8.51963 4.3437 7.71194C3.56246 6.90343 2.4694 6.47456 1.59438 6.24624C1.57423 6.24098 1.55417 6.23583 1.53422 6.23077C1.55417 6.22571 1.57423 6.22056 1.59438 6.2153C2.46954 5.98695 3.56281 5.55797 4.34408 4.74921L4.34409 4.74919C5.13032 3.93517 5.58121 2.74031 5.8366 1.77649C5.8647 1.67045 5.89054 1.56677 5.91429 1.46618C5.93803 1.56677 5.96388 1.67045 5.99197 1.77649C6.24736 2.74031 6.69825 3.93517 7.48448 4.74919Z"
          fill="white" stroke="#FFC9FA" stroke-width="0.5" />
      </svg>
      <svg class="twinkle" width="5" height="6" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.48448 4.74919L7.48525 4.74998C8.27224 5.55764 9.3644 5.98997 10.238 6.22176C10.2627 6.22833 10.2873 6.23474 10.3118 6.241C10.2876 6.2471 10.2633 6.25334 10.2389 6.25973C9.36512 6.48814 8.27254 6.91704 7.48525 7.72501C6.69838 8.53255 6.24737 9.72457 5.992 10.6866C5.96415 10.7915 5.93852 10.8941 5.91495 10.9937C5.89103 10.8921 5.86498 10.7873 5.83663 10.6801C5.58135 9.71499 5.13048 8.51963 4.3437 7.71194C3.56246 6.90343 2.4694 6.47456 1.59438 6.24624C1.57423 6.24098 1.55417 6.23583 1.53422 6.23077C1.55417 6.22571 1.57423 6.22056 1.59438 6.2153C2.46954 5.98695 3.56281 5.55797 4.34408 4.74921L4.34409 4.74919C5.13032 3.93517 5.58121 2.74031 5.8366 1.77649C5.8647 1.67045 5.89054 1.56677 5.91429 1.46618C5.93803 1.56677 5.96388 1.67045 5.99197 1.77649C6.24736 2.74031 6.69825 3.93517 7.48448 4.74919Z"
          fill="white" stroke="#FFC9FA" stroke-width="0.5" />
      </svg>
      <svg class="twinkle" width="9" height="7" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.48448 4.74919L7.48525 4.74998C8.27224 5.55764 9.3644 5.98997 10.238 6.22176C10.2627 6.22833 10.2873 6.23474 10.3118 6.241C10.2876 6.2471 10.2633 6.25334 10.2389 6.25973C9.36512 6.48814 8.27254 6.91704 7.48525 7.72501C6.69838 8.53255 6.24737 9.72457 5.992 10.6866C5.96415 10.7915 5.93852 10.8941 5.91495 10.9937C5.89103 10.8921 5.86498 10.7873 5.83663 10.6801C5.58135 9.71499 5.13048 8.51963 4.3437 7.71194C3.56246 6.90343 2.4694 6.47456 1.59438 6.24624C1.57423 6.24098 1.55417 6.23583 1.53422 6.23077C1.55417 6.22571 1.57423 6.22056 1.59438 6.2153C2.46954 5.98695 3.56281 5.55797 4.34408 4.74921L4.34409 4.74919C5.13032 3.93517 5.58121 2.74031 5.8366 1.77649C5.8647 1.67045 5.89054 1.56677 5.91429 1.46618C5.93803 1.56677 5.96388 1.67045 5.99197 1.77649C6.24736 2.74031 6.69825 3.93517 7.48448 4.74919Z"
          fill="white" stroke="#FFC9FA" stroke-width="0.5" />
      </svg>
    </span>
    <span class="name">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  name: 'TwinkleButton',
  props: {
    theme: {
      type: String,
      default: 'purple',
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[twinkle-button] { .rel; .crop; .font-g; .br(8);
  &::before, &::after { .cnt; .abs; .z(1); .wh(50%, 100%); .bg-h(100%); .no-repeat; }
  &::before { .lt(0, 0); .bg-xy(0, 0); }
  &::after { .rt(0, 0); .bg-xy(100%, 0); }

  > span { .rel; .ib; .p(0, 44); line-height: 100%;
    &.bg { .wh(100%); }
    &.name { .w(100%); .abs; .lt(50%, 50%); .t-xyc; .z(2); }
  }

  .twinkle { .abs; .z(2);
    &:nth-child(1) { .wh(24); .lt(21, 2); animation: twinkle 3s 2s ease-in-out infinite; }
    &:nth-child(2) { .wh(10); .lb(13, 16); animation: twinkle 2.2s 1s ease-in-out infinite; }
    &:nth-child(3) { .wh(18); .rb(11, 8); animation: twinkle 2.6s 0.7s ease-in-out infinite; }
  }

  &.navy { .c(#fff); .bgc(#240d55); transition: background-color 0.25s, border 0.25s; .-a(#240d55, 4px);
    &::before { .bg('@{do}/twinkle-bg-l.svg'); }
    &::after { .bg('@{do}/twinkle-bg-r.svg'); }
    &:hover { .bgc(#6840bc); }
    &:active { .bgc(#6840bc); }
    .twinkle {
      > path { stroke: #ffc9fa; }
    }
  }

  &.purple { .c(#fff); .bgc(#940e76); transition: background-color 0.25s, border 0.25s; .-a(#940e76, 4px);
    &::before { .bg('@{do-event}/btn-purple-left.svg'); }
    &::after { .bg('@{do-event}/btn-purple-right.svg'); }
    &:hover { .bgc(#c813a1); }
    &:active { .bgc(#c813a1); }
    .twinkle {
      > path { stroke: #c9dcff; }
    }
  }

  &.blue { .c(#fff); .bgc(#005eb8); transition: background-color 0.25s, border 0.25s; .-a(#005eb8, 4px);
    &::before { .bg('@{do-event}/btn-blue-left.svg'); }
    &::after { .bg('@{do-event}/btn-blue-right.svg'); }
    &:hover { .bgc(#005eb8); }
    &:active { .bgc(#0075e1); }
    .twinkle {
      > path { stroke: #c9dcff; }
    }
  }

  &.sky { .c(#fff); .bgc(#8598ff); transition: background-color 0.25s, border 0.25s; .-a(#8598ff, 4px);
    &::before { .bg('@{do-event}/btn-sky-left.svg'); }
    &::after { .bg('@{do-event}/btn-sky-right.svg'); }
    &:hover { .bgc(#8598ff); }
    &:active { .bgc(#8598ff); }
    .twinkle {
      > path { stroke: #c9d5ff; }
    }
  }

  &:disabled { .c(#fff); .bgc(#919497); .o(0.4); transition: background-color 0.25s, border 0.25s; .-a(#919497, 4px);
    &::before { .bg('@{do-event}/btn-gray-left.svg'); }
    &::after { .bg('@{do-event}/btn-gray-right.svg'); }
    &:hover { .bgc(#919497); }
    &:active { .bgc(#919497); }
    .twinkle {
      > path { stroke: #919497; }
    }

    .bg {
      > svg { animation: none; }
    }
  }

  @media (@tl-up) {
    > span { .p(0, 57); }
    .twinkle {
      &:nth-child(1) { .lt(86, 4); }
      &:nth-child(2) { .lb(76, 24); }
      &:nth-child(3) { .rb(88, 4); }
    }
    &.purple {
      &:hover { .bgc(#c813a1); .-a(#c813a1, 4); }

    }

    &.blue {
      &:hover { .bgc(#0075e1); .-a(#0075e1, 4); }
    }

    &:disabled {
      &:hover { .bgc(#919497); .-a(#919497, 4); }
    }
  }
}
</style>
