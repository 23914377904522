<template>
  <PSection event-background theme="white">
    <div application-event-background>
      <div class="all-bg">
        <img src="/img/pages/loldocup/event/bg-1920.svg" class="pc" alt="all-bg">
        <img src="/img/pages/loldocup/event/bg-1024.svg" class="tablet" alt="all-bg">
        <img src="/img/pages/loldocup/event/bg-320.svg" class="mb" alt="all-bg">
      </div>
      <img src="/img/pages/loldocup/event/bg-ribbon.png" class="bg-ribbon" alt="bg-ribbon">
      <div class="ari-bg">
        <div class="right">
          <img src="/img/pages/loldocup/event/keyart-ari.svg" alt="keyart-ari" v-prx:intro="{ r: [3, 20], tx: [500, 0], o: [0, 1] }">
        </div>
        <div class="left">
          <img src="/img/pages/loldocup/event/keyart-ari-left.svg" alt="keyart-ari" v-prx:intro="{ r: [10, 30], tx: [-500, 0], o: [0, 1] }">
        </div>
      </div>
      <div class="balloon">
        <img class="balloon1" src="/img/pages/loldocup/event/blue_balloon.svg" alt="balloon1" />
        <img class="balloon2" src="/img/pages/loldocup/event/pink_balloon.svg" alt="balloon2" />
        <img class="balloon3" src="/img/pages/loldocup/event/purple_balloon.svg" alt="balloon3" />
      </div>
    </div>
  </PSection>
</template>

<script>
export default {
  name: 'EventBackground',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[application-event-background] { .rel; .z(1);
  .all-bg { .abs; .lt(0, -407); .z(1); .w(100%); .tc;
    > img { .w(100%); .hide;
      &.mb { .ib; .max-w(480); .mh-c; .mt(20);}
    }
  }
  .bg-ribbon { .abs; .lt(0, -100); .z(1); .w; }
  .ari-bg { .abs; .lt(0, 0); .z(2); .w(100%);
    > div {
      img { .max-w(700); .w(37%); }
      &.right { .tr; }
      &.left { .tl;}
    }
  }
  .balloon { .abs; .lt(0, 0); .z(1); .hide; .w(100%);
    .balloon1 { .abs; .rt(70, 80); .z(1); animation: dodongsil 3s 0.5s ease-in-out infinite;}
    .balloon2 { .abs; .lt(100, 300); .z(1); animation: dodongsil 3s 0.8s ease-in-out infinite;}
    .balloon3 { .abs; .rt(40, 800); .z(1); animation: dodongsil 3s 1s ease-in-out infinite;}
  }

  @media (@tl-up) {
    .all-bg { .lt(0, -401);
      > img {
        &.tablet { .block; }
      }
    }
    .bg-ribbon { .lt(0, -271); .w(107%); }
  }

  @media (@ds-up) {
    .all-bg { .lt(0, -407);
      > img {
        &.pc { .block; }
      }
    }
    .bg-ribbon { .lt(0, -100); .w; }
    .balloon { .block;}
  }
}
</style>
