<template>
  <div congratulation-effect>
    <svg v-que="{delay:500}" width="29" height="50" viewBox="0 0 29 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.2088 46.7759C23.3779 45.7404 9.25166 38.0465 4.05308 28.1981C-1.1455 18.3496 19.4995 9.94015 20.5719 8.49213C21.6442 7.04411 28.3548 0.692351 28.3548 0.692351C28.3548 0.692351 4.95391 -0.496549 0.98114 23.029C-2.99163 46.5545 18.2502 49.8395 18.2502 49.8395L27.2088 46.7759Z" fill="#9DA1FF" />
    </svg>

    <svg v-que="{delay:502}" width="80" height="105" viewBox="0 0 80 105" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.9853 86.3858C24.0371 89.4094 29.1968 92.3152 37.1775 90.5738C51.8897 87.3626 53.1891 75.3369 53.1891 75.3369C53.1891 75.3369 68.6838 81.0662 79.011 104.553L77.7875 81.7804C77.7875 81.7804 71.2045 63.989 52.0896 58.587C52.0896 58.587 52.6991 47.3331 35.1275 39.4012C17.5559 31.4693 9.25554 30.3185 5.34148 26.3882C5.34148 26.3882 9.23899 21.1343 12.2458 18.7C15.2527 16.2657 14.4721 14.3821 14.4721 14.3821L13.654 0.108726C13.654 0.108726 13.3904 2.55562 9.93942 5.03339C6.48844 7.51117 1.29173 12.696 0.301724 19.0028L1.00677 30.99C1.00677 30.99 0.541606 38.8832 7.50006 43.5824C14.4585 48.2816 32.8867 53.0855 36.4296 55.8032C36.4296 55.8032 16.2392 56.6115 16.5335 65.3793L17.1581 77.7433C17.3119 80.9984 18.6771 84.0812 20.9853 86.3858ZM42.3712 72.198C30.9684 77.9911 23.7471 73.6921 23.7471 73.6921C32.621 69.2382 42.7168 72.0231 42.3712 72.198Z" fill="#C1D6FF" />
    </svg>

    <svg v-que="{delay:409}" width="11" height="24" viewBox="0 0 11 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.99581 0.814453L0.00891113 9.26764C0.00891113 9.26764 1.5678 19.7003 10.5508 23.6551C10.5508 23.6551 8.82044 21.1403 8.99581 0.814453Z" fill="#FFB7FD" />
    </svg>

    <svg v-que="{delay:501}" width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.29397 0.494141L-0.000896454 7.31743C-0.000896454 7.31743 1.65054 16.5855 10.8638 18.5192C20.0771 20.453 22.1507 30.3627 22.1507 30.3627L29.8584 22.3809C29.8584 22.3809 26.4158 14.0072 18.5715 11.6901C10.7272 9.37313 7.29397 0.494141 7.29397 0.494141Z" fill="#97E8FB" />
    </svg>

    <svg v-que="{delay:408}" width="40" height="25" viewBox="0 0 40 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.49161 0.852883L0.117739 12.3991C0.117739 12.3991 7.40354 21.4416 18.0291 18.2336C28.6546 15.0256 36.7609 24.5173 36.7609 24.5173L39.8598 11.485C39.8598 11.485 31.2745 4.43554 21.8233 6.44395C12.3721 8.45237 3.49161 0.852883 3.49161 0.852883Z" fill="#FFD671" />
    </svg>

    <svg v-que="{delay:407}" width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="3.71137" cy="3.98187" r="3.10492" fill="#FFE49F" />
    </svg>

    <svg v-que="{delay:409}" width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0767 31.0941C13.6157 31.0941 13.2059 30.7929 13.0266 30.3275L10.2347 22.4967L8.05758 23.5646C7.64776 23.7562 7.13549 23.6741 6.80251 23.3181C6.46954 22.9622 6.39269 22.442 6.57199 21.9765L7.41724 19.9504L0.808955 17.2671C0.373526 17.1028 0.0917969 16.6647 0.0917969 16.1719C0.0917969 15.679 0.373526 15.241 0.808955 15.0493L7.62216 12.2017L6.57199 9.71015C6.39269 9.27207 6.46954 8.72446 6.80251 8.3959C7.13549 8.03996 7.62212 7.95781 8.03194 8.14947L10.3372 9.21731L13.0266 0.838939C13.1802 0.346095 13.6157 0.0449219 14.0767 0.0449219C14.5634 0.0449219 14.9732 0.373485 15.1269 0.86633L17.6626 8.86137L20.0703 7.60187C20.5057 7.38282 21.018 7.46496 21.3509 7.82091C21.6839 8.17685 21.7608 8.72445 21.5559 9.18992L20.1728 12.1744L27.3189 15.0767C27.7543 15.241 28.0361 15.7064 28.0361 16.1993C28.0361 16.6921 27.7543 17.1302 27.3189 17.3219L20.2496 20.2242L21.018 22.0586C21.1973 22.4967 21.1204 23.0443 20.7875 23.3729C20.4545 23.7289 19.9678 23.811 19.558 23.6193L17.7907 22.7705L15.1269 30.3549C14.9732 30.793 14.5634 31.0941 14.0767 31.0941Z" fill="#99EEEE" />
    </svg>

    <svg v-que="{delay:502}" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.24584" cy="7.87865" r="6.98607" fill="#80B2FF" />
    </svg>

    <svg v-que="{delay:501}" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.4586 5.85643C8.51379 3.94519 8.16172 0.15625 8.16172 0.15625C8.16172 0.15625 7.80965 3.94519 5.86488 5.85643C3.93688 7.7509 0.399414 7.91855 0.399414 7.91855C0.399414 7.91855 3.93688 8.0862 5.86488 9.98067C7.80965 11.8751 8.16172 15.6809 8.16172 15.6809C8.16172 15.6809 8.51379 11.8919 10.4586 9.99744C12.4033 8.10297 15.924 7.93532 15.924 7.93532C15.924 7.93532 12.4033 7.7509 10.4586 5.85643Z" fill="#FFBEFD" />
    </svg>

    <svg v-que="{delay:407}" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.98607" cy="6.98607" r="6.98607" fill="#FFE49F" />
    </svg>

    <svg v-que="{delay:407}" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0956 9.12029C12.984 6.06231 12.4207 0 12.4207 0C12.4207 0 11.8573 6.06231 8.74572 9.12029C5.66092 12.1514 0.000976562 12.4197 0.000976562 12.4197C0.000976562 12.4197 5.66092 12.6879 8.74572 15.7191C11.8573 18.7502 12.4207 24.8394 12.4207 24.8394C12.4207 24.8394 12.984 18.7771 16.0956 15.7459C19.2072 12.7148 24.8403 12.4465 24.8403 12.4465C24.8403 12.4465 19.2072 12.1514 16.0956 9.12029Z" fill="#B9DDFF" />
    </svg>

    <svg v-que="{delay:500}" width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.28636 0L8.72086 6.3843C8.72086 6.3843 7.43125 14.2636 0 17.2505C0 17.2505 1.43144 15.3512 1.28636 0Z" fill="#97E8FB" />
    </svg>

    <svg v-que="{delay:503}" width="38" height="42" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.2755 0.376395L37.058 7.99204C37.058 7.99204 36.5697 20.4239 24.857 24.6193C13.1443 28.8148 12.219 42.1629 12.219 42.1629L0.686782 33.1051C0.686782 33.1051 3.68159 21.5206 13.5329 17.0706C23.3841 12.6207 26.2755 0.376395 26.2755 0.376395Z" fill="#C1D6FF" />
    </svg>

    <svg v-que="{delay:502}" width="74" height="93" viewBox="0 0 74 93" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M72.0737 55.8326C73.8087 52.3542 74.846 47.0976 71.0912 40.7316C64.1684 28.9962 53.4656 31.2823 53.4656 31.2823C53.4656 31.2823 54.005 16.3447 71.2678 0.823339L52.0428 8.31862C52.0428 8.31862 38.6152 19.0105 39.3815 36.9668C39.3815 36.9668 29.5371 39.6275 27.6924 56.9733C25.8476 74.3192 27.2073 81.7783 24.9371 86.2543C24.9371 86.2543 19.3189 84.3913 16.376 82.496C13.4331 80.6007 12.0352 81.8046 12.0352 81.8046L-0.000203339 86.5465C-0.000203339 86.5465 2.17729 86.0806 5.28324 88.3453C8.38919 90.6101 14.3156 93.6091 20.016 92.6754L30.1185 88.6775C30.1185 88.6775 37.0337 86.8438 39.1033 79.5338C41.1729 72.2239 40.087 55.0269 41.4202 51.2131C41.4202 51.2131 47.828 68.3365 55.28 65.6026L65.7291 61.5672C68.4831 60.514 70.7462 58.4684 72.0737 55.8326ZM53.829 41.4677C62.0343 49.6282 60.3829 57.0508 60.3829 57.0508C54.0442 50.6847 53.5809 41.2201 53.829 41.4677Z" fill="#FAC1FF" />
    </svg>

    <svg v-que="{delay:409}" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2883 0.758616L13.2733 10.1718C13.2733 10.1718 7.57043 15.6177 8.5936e-05 13.588C8.5936e-05 13.588 2.24779 12.9361 11.2883 0.758616Z" fill="#FFB571" />
    </svg>

    <svg v-que="{delay:407}" width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.4369 0.709786L30.8732 8.61377C30.8732 8.61377 26.9007 16.1764 18.4013 15.3198C9.90189 14.4631 5.38809 22.4648 5.38809 22.4648L0.912754 13.4455C0.912754 13.4455 6.18892 7.14807 13.6095 7.29742C21.0301 7.44677 26.4369 0.709786 26.4369 0.709786Z" fill="#FF8080" />
    </svg>

    <svg v-que="{delay:406}" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0591 5.70018C8.11437 3.78894 7.7623 0 7.7623 0C7.7623 0 7.41023 3.78894 5.46547 5.70018C3.53746 7.59465 0 7.7623 0 7.7623C0 7.7623 3.53746 7.92995 5.46547 9.82442C7.41023 11.7189 7.7623 15.5246 7.7623 15.5246C7.7623 15.5246 8.11437 11.7357 10.0591 9.84119C12.0039 7.94672 15.5246 7.77907 15.5246 7.77907C15.5246 7.77907 12.0039 7.59465 10.0591 5.70018Z" fill="#FFBEFD" />
    </svg>

    <svg v-que="{delay:408}" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0946 9.12029C12.983 6.06231 12.4197 0 12.4197 0C12.4197 0 11.8564 6.06231 8.74474 9.12029C5.65994 12.1514 0 12.4197 0 12.4197C0 12.4197 5.65994 12.6879 8.74474 15.7191C11.8564 18.7502 12.4197 24.8394 12.4197 24.8394C12.4197 24.8394 12.983 18.7771 16.0946 15.7459C19.2062 12.7148 24.8394 12.4465 24.8394 12.4465C24.8394 12.4465 19.2062 12.1514 16.0946 9.12029Z" fill="#99EEBB" />
    </svg>

    <svg v-que="{delay:502}" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.98607" cy="6.98607" r="6.98607" fill="#FFE49F" />
    </svg>

    <svg v-que="{delay:501}" width="41" height="26" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.7326 6.5401C37.3612 9.45811 29.0752 19.7947 20.1792 22.8324C11.2831 25.8701 7.01713 8.61711 5.95298 7.61052C4.88884 6.60392 0.487388 0.600798 0.487388 0.600798C0.487388 0.600798 -3.60995 18.986 15.4644 24.6985C34.5387 30.4109 40.0958 13.9628 40.0958 13.9628L38.7326 6.5401Z" fill="#72DAC7" />
    </svg>

    <svg v-que="{delay:408}" width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9849 31.0492C13.5239 31.0492 13.1141 30.748 12.9348 30.2826L10.1429 22.4518L7.96578 23.5196C7.55597 23.7113 7.04369 23.6292 6.71072 23.2732C6.37774 22.9173 6.30089 22.397 6.48019 21.9316L7.32545 19.9055L0.717158 17.2222C0.281729 17.0579 0 16.6198 0 16.127C0 15.6341 0.281729 15.196 0.717158 15.0044L7.53037 12.1568L6.48019 9.66523C6.30089 9.22715 6.37774 8.67954 6.71072 8.35098C7.04369 7.99504 7.53033 7.91289 7.94014 8.10455L10.2454 9.17239L12.9348 0.794018C13.0885 0.301173 13.5239 0 13.9849 0C14.4716 0 14.8814 0.328563 15.0351 0.821408L17.5708 8.81645L19.9785 7.55695C20.4139 7.3379 20.9262 7.42004 21.2591 7.77598C21.5921 8.13193 21.669 8.67953 21.4641 9.145L20.081 12.1295L27.2271 15.0318C27.6625 15.196 27.9443 15.6615 27.9443 16.1544C27.9443 16.6472 27.6625 17.0853 27.2271 17.2769L20.1578 20.1793L20.9262 22.0137C21.1055 22.4518 21.0286 22.9994 20.6957 23.328C20.3627 23.6839 19.876 23.7661 19.4662 23.5744L17.6989 22.7256L15.0351 30.31C14.8814 30.748 14.4716 31.0492 13.9849 31.0492Z" fill="#FFE49F" />
    </svg>

    <svg v-que="{delay:409}" width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.10973 0L18.0646 4.22717C18.0646 4.22717 9.84923 11.4518 9.57435 15.4022L0 11.587C0 11.587 0.537405 4.37473 7.10973 0Z" fill="#C8A0FB" />
    </svg>
  </div>
</template>

<script>
import que from '@/directives/que';

export default {
  name: 'CongratulationEffect',
  directives: { que },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[congratulation-effect] { .abs; .lt(0, 0); .r(0); .z(1); .wh(100%);
  > svg { .abs; .lb(45%, 0); .r(0); .z(1);
    &:nth-child(1) { transform: scale(0) rotate(0deg) translate(0px, 0px); .o(0); transition: all 0.3s cubic-bezier(0.1, 0.91, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(-30deg) translate(0px, -230px); .o(1);
      }
    }
    &:nth-child(2) { transform: scale(0) rotate(44deg) translate(0px, 0px); .o(0); transition: all 0.35s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(8deg) translate(-224px, -84px); .o(1);
      }
    }
    &:nth-child(3) { transform: scale(0) rotate(28deg) translate(0px, 0px); .o(0); transition: all 0.3s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(-277px, -224px); .o(1);
      }
    }
    &:nth-child(4) { transform: scale(0) rotate(28deg) translate(0px, 0px); .o(0); transition: all 0.37s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(-336px, -167px); .o(1);
      }
    }
    &:nth-child(5) { transform: scale(0) rotate(28deg) translate(0px, 0px); .o(0); transition: all 0.34s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(-327px, -64px); .o(1);
      }
    }
    &:nth-child(6) { transform: scale(0) rotate(28deg) translate(0px, 0px); .o(0); transition: all 0.33s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(-260px, -168px); .o(1);
      }
    }
    &:nth-child(7) { transform: scale(0) rotate(28deg) translate(0px, 0px); .o(0); transition: all 0.3s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(-253px, -97px); .o(1);
      }
    }
    &:nth-child(8) { transform: scale(0) rotate(28deg) translate(0px, 0px); .o(0); transition: all 0.31s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(-205px, -18px); .o(1);
      }
    }
    &:nth-child(9) { transform: scale(0) rotate(28deg) translate(0px, 0px); .o(0); transition: all 0.34s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(-166px, -67px); .o(1);
      }
    }
    &:nth-child(10) { transform: scale(0) rotate(28deg) translate(0px, 0px); .o(0); transition: all 0.32s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(-106px, -51px); .o(1);
      }
    }
    &:nth-child(11) { transform: scale(0) rotate(28deg) translate(0px, 0px); .o(0); transition: all 0.33s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(-93px, -117px); .o(1);
      }
    }
    &:nth-child(12) { transform: scale(0) rotate(-28deg) translate(0px, 0px); .o(0); transition: all 0.32s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(134px, -228px); .o(1);
      }
    }
    &:nth-child(13) { transform: scale(0) rotate(-28deg) translate(0px, 0px); .o(0); transition: all 0.3s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(169px, -190px); .o(1);
      }
    }
    &:nth-child(14) { transform: scale(0) rotate(-28deg) translate(0px, 0px); .o(0); transition: all 0.31s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(244px, -183px); .o(1);
      }
    }
    &:nth-child(15) { transform: scale(0) rotate(-28deg) translate(0px, 0px); .o(0); transition: all 0.3s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(361px, -171px); .o(1);
      }
    }
    &:nth-child(16) { transform: scale(0) rotate(-28deg) translate(0px, 0px); .o(0); transition: all 0.34s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(350px, -60px); .o(1);
      }
    }
    &:nth-child(17) { transform: scale(0) rotate(-28deg) translate(0px, 0px); .o(0); transition: all 0.35s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(120px, -144px); .o(1);
      }
    }
    &:nth-child(18) { transform: scale(0) rotate(-28deg) translate(0px, 0px); .o(0); transition: all 0.31s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(215px, -104px); .o(1);
      }
    }
    &:nth-child(19) { transform: scale(0) rotate(-28deg) translate(0px, 0px); .o(0); transition: all 0.32s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(305px, -151px); .o(1);
      }
    }
    &:nth-child(20) { transform: scale(0) rotate(-28deg) translate(0px, 0px); .o(0); transition: all 0.33s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(297px, -97px); .o(1);
      }
    }
    &:nth-child(21) { transform: scale(0) rotate(-28deg) translate(0px, 0px); .o(0); transition: all 0.31s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(158px, -55px); .o(1);
      }
    }
    &:nth-child(22) { transform: scale(0) rotate(-28deg) translate(0px, 0px); .o(0); transition: all 0.32s cubic-bezier(0.01, 1.05, 0.46, 0.93);
      &.que-done {
        transform: scale(1) rotate(0deg) translate(257px, -15px); .o(1);
      }
    }
  }

  @keyframes animate {
    0% {
      transform: rotate(0deg) translate(0px, 0px);
    }
    100% {
      transform: rotate(45deg) translate(0px, -250px);
    }
  }
}
</style>
