<template>
  <modal event-apply-confirm-modal @close="close" @resolve="resolve">
    <div class="image">
      <TwinKleEffect />
      <img src="/img/pages/loldocup/event/gift-box.svg" alt="gift-box">
    </div>
    <div class="text-holder">
      <h2><em>{{ item.contributor }} 애장품</em>에 응모하시겠습니까?</h2>
      <span>*애장품 응모 시 변경 불가능 합니다.</span>
    </div>
    <div class="btn-group">
      <button class="no" @click="close">아니오</button>
      <button class="ok" @click="resolve">네</button>
    </div>
  </modal>
</template>

<script>
import Modal from 'shared/components/common/Modal.vue';
import Specific from 'shared/types/Specific';
import applyGifts from '@/data/loldocup/applyGift';
import EventSuccessModal from '@/views/components/loldocup/modal/EventSuccessModal.vue';
import TwinKleEffect from '@/views/components/loldocup/effect/TwinKleEffect.vue';
import EventAlertModal from '@/views/components/loldocup/modal/EventAlertModal.vue';

export default {
  name: 'EventApplyConfirmModal',
  components: { TwinKleEffect, Modal },
  props: {
    options: {
      type: Object,
      default: Specific,
    },
  },
  computed: {
    /** @return {string} */
    gameId() {
      return this.$route.params.id || '';
    },
    /** @return {string} */
    cherishedItemId() {
      return this.options?.cherishedItemId || '';
    },
    item() {
      return applyGifts.find(item => item.itemId === this.cherishedItemId) ?? {};
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async resolve() {
      this.close();

      if (!this.cherishedItemId) {
        this.$modal(EventAlertModal, { message: '응모할 애장품을 선택해주세요.', button: '확인' });
        return;
      }

      this.$scroll.scrollTo(0);
      this.$modal(EventSuccessModal);

      this.$emit('resolve');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[event-apply-confirm-modal] { .tc;
  > .panel {
    &.large { .max-w(319); .w(90%); }
    > .header { .pt(27);
      .close svg { .wh(31);
        .stroke-target { stroke-width: 2px; }
      }
    }
    .image { .rel;
      [twinkle-effect] { .wh(240, 133); transform: scale(0.8); }
      > img { .wh(82, 103); }
    }
    .text-holder { .mt(21); .mb(16);
      h2 { .fs(14, 18);
        > em { .c(#005eb8);}
      }
      span { .fs(12, 16); .c(#cc4dc8); }
    }
    .btn-group { .pb(18);
      button { .max-w(240); .calc-w(50%, 5.5); .h(36); .br(8);}
      .no { .fs(16); .c(#fff); .font-g; .bgc(#484a4b); .mr(11); }
      .ok { .fs(16); .c(#fff); .font-g; .bgc(#005eb8); }
    }
  }

  @media (@tl-up) {
    > .panel {
      &.large { .max-w(628); .w(100%); }
      > .header { .pt(40);
        .close svg { .wh(40); }
      }
      .image {
        [twinkle-effect] { .wh(254, 156); transform: scale(1); }
        > img { .wh(180, 150); }
      }
      .text-holder { .mt(32); .mb(42);
        h2 { .fs(24, 31); }
        span { .fs(16, 21); }
      }
      .btn-group { .pb(30);
        button { .calc-w(50%, 10); .h(52); }
        .no { .fs(24); .mr(20); }
        .ok { .fs(24); }
      }
    }
  }
}

</style>
