<template>
  <div application-event-item class="item-box" @click="$emit('click')">
    <div class="item">
      <div class="image-holder">
        <div class="image-con">
          <img class="image" :src="item.imageUrl" />
          <span class="size">{{ item.size }}</span>
        </div>
        <div class="clicked-box">
          <svg width="32" height="23" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.9" d="M4 12.3231L11.7913 19L28 4" stroke="white" stroke-width="5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <p>선택 완료</p>
        </div>
      </div>
      <div class="item-name">{{ item.contributor }}</div>
      <p class="detail">{{ item.item }}</p>
      <p v-if="item.count" class="badge">{{ item.count }}명</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationEventItem',
  props: {
    item: {
      type: [Object, String],
      required: true,
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[application-event-item] { pointer-events: none;
  .item { .tl; .mb(6); .p(17, 17, 17, 19); .h(198); .bg('@{do-event}/m/bg_m.svg'); .bg-c; .no-repeat; .contain;
    * { pointer-events: auto; .pointer;}
    .image-holder { .crop; .mh-c; .mb(10); .wh(100%, 124.5); .br(10);
      .image-con { .rel;
        .size { .abs; .rb(8, 8); .z(1); .fs(9, 9px); .semi-bold; .c(#fff); .font-g; }
      }
      .image { .wh(100%); }
      .clicked-box { .hide;}
    }
    .item-name { .mb(0); .c(#0067c6); .fs(14, 14px); .ls(-1.5); .bold; }
    .detail { .ib; .fs(10, 9px); .ls(-1.5);}
    .badge { .ib; .fs(8, 14px); .c(white); .h(14); .tc; .br(10); .ml(4); .p(0, 4); }
  }

  &.select {
    .image-holder { .rel;
      .image-con {
        .size { .z(0);}
      }
      .clicked-box { .abs; .lt(0, 0); .block; .wh(100%); .bgc(rgba(0, 0, 0, 0.6)); .tc; .flex-center; flex-direction: column;
        > svg { .wh(45, 35);
          > path { stroke-width: 5;}
        }
        > img { .mt(49); .mb(20); .o(.8);}
        > p { .c(white); .fs(16, 16px); .ls(-1); .o(.8); .mt(5); }
      }
    }
  }

  @media (@tl-up) {
    .item { .h(100%); .p(28, 28, 28, 30); .bg('@{do-event}/1024/bg_1024.svg');
      .image-holder { .mb(20); .wh(100%, 165.5);
        .image-con { .rel;
          .size { .rb(10, 10); .fs(12, 12px); .bold; }
        }
      }
      .item-name { .mb(4); .fs(20, 20px); }
      .detail { .fs(11, 11px); }
      .badge { .fs(10, 16px); .h(16); .p(0, 6); }
    }

    &.select {
      .image-holder {
        .clicked-box { .wh(100%);
          > svg { .wh(65, 50);
            > path { stroke-width: 5; }
          }
          > p { .mt(10); .fs(18, 18); }
        }
      }
    }
  }

  @media ( @ds-up) {
    .max-w(300);
    .item { .wh(100%, 384); .p(36, 36, 36, 38); .bg('@{do-event}/1024/bg_1920.svg');
      .image-holder { .h(223);
        .image-con { .rel;
          .size { .rb(16, 16); .fs(16, 16px);}
        }
      }
      .item-name {.fs(30, 30px); .mb(7); }
      .detail { .fs(16, 16px); }
      .badge { .fs(14, 22px); .h(22); .p(0, 8); }
    }

    &.select {
      .image-holder {
        .clicked-box { .wh(100%);
          > svg { .wh(94, 68); }
          > p { .fs(24, 24); }
        }
      }
    }
  }
}
</style>
