<template>
  <PSection loldocup-event>
    <ApplicationEventVisual />
    <div class="cherished-item-container">
      <div class="rule">
        <div class="main">
          <div v-que="{animation:'fadeIn', delay: 1300, duration: 800, ioRatio: 0.1}" class="bg-effect"></div>
          <div v-que="{animation:'fadeIn', delay: 600, duration: 800}" class="text">
            <b>대회에 참가하여 경기 진행 시 </b><br>응모권이 발급되며<br>애장품 응모는 <b>1회</b>만 가능합니다.
          </div>
        </div>
        <ul v-que="{animation:'fadeIn', delay: 600, duration: 800}" class="detail">
          <li>응모 기간: 12월 14일 (화) 18시까지</li>
          <li>당첨발표: 아마추어 오픈토너먼트 본선 생방송에서 확인 가능</li>
        </ul>
      </div>
      <EventBackground />
      <div class="contents-holder">
        <div v-que="{animation:'fadeIn', delay: 900, duration: 800}" class="notice">원하는 애장품 선택 후 아래 <br /><em>응모하기 버튼</em>을 선택해주세요
        </div>
        <div class="items-holder">
          <div class="items">
            <PItem tag="div" v-for="item in giftList" :key="`application-${item.itemId}`" class="item-holder">
              <ApplicationEventItem :item="item" :class="{'select': item.itemId === select}"
                @click="onClick(item.itemId)" />
            </PItem>
          </div>
        </div>
        <twinkle-button theme="blue" :class="['btn', {'select': Boolean(select)}]" @click="participateEvent" disabled>응모하기
        </twinkle-button>
      </div>
    </div>
    <div class="bottom-graphics">
      <img src="/img/pages/loldocup/event/event-bottom-left.svg" class="left" alt="bottom-left">
      <img src="/img/pages/loldocup/event/event-bottom-right.svg" class="right" alt="bottom-right">
    </div>
  </PSection>
</template>

<script>
import { getter } from 'shared/utils/storeUtils';
import gtag from 'shared/mixins/gtag';
import meta from '@/data/meta';
import que from '@/directives/que';
import applyGifts from '@/data/loldocup/applyGift';
import EventApplyConfirmModal from '@/views/components/loldocup/modal/EventApplyConfirmModal.vue';
import ApplicationEventItem from '@/views/components/loldocup/ApplicationEventItem.vue';
import ApplicationEventVisual from '@/views/components/loldocup/ApplicationEventVisual.vue';
import TwinkleButton from '@/views/components/loldocup/TwinkleButton.vue';
import EventBackground from '@/views/components/loldocup/effect/EventBackground.vue';
import EventAlertModal from '@/views/components/loldocup/modal/EventAlertModal.vue';

export default {
  name: 'LoldocupEvent',
  directives: { que },
  mixins: [gtag],
  components: { EventBackground, TwinkleButton, ApplicationEventVisual, ApplicationEventItem },
  data() {
    return {
      select: '',
    };
  },
  computed: {
    gameId() {
      return this.$route.params.id || '';
    },
    giftList() {
      return applyGifts;
    },
    isLogin: getter('auth', 'isLogin'),
    timeDiff() {
      if (['development', 'local'].includes(process.env.VUE_APP_ENV)) return 0;
      return this.$store.getters.timeDiff ?? 0;
    },
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    onClick(itemId) {
      this.trackEvent(`GIFT_choice`, 'click', `${this.upRouteGameId}`);
      this.select = this.select === itemId ? '' : itemId;
    },
    async participateEvent() {
      this.trackEvent(`GIFT_apply_btn`, 'click', `${this.upRouteGameId}`);
      const modalObj = { message: this.$t('applyGift.errorCode.END_EVENT_DATE'), button: '확인', callback: null };
      this.$modal(EventAlertModal, modalObj).then(() => {
        this.select = '';
        if (modalObj.callback) modalObj.callback();
      });
    },
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[loldocup-event] { .font-g;
  .cherished-item-container { .crop; .tc; .p(100, 0, 60); .bgc(#fbe2ff); .bg('@{do-event}/title_elements_m.svg'); background-size: auto; .no-repeat; .bg-xy(50%, 65);
    .rule { .mb(108);
      .main { .rel; .z(3); .max-w(1280); .p(20, 0); .mh-c; .fs(26, 30); .ls(-2); .c(#1d399c);
        &::before, &::after { .cnt; .block; .abs; .z(2); }

        &::before { .lt(5vw, -60); .wh(90, 86); .cover('@{do-event}/ballon_left.svg'); .no-repeat; }
        &::after { .rt(3vw, -70); .wh(95, 97); .cover('@{do-event}/ballon_right.svg'); .no-repeat; }
        .text { .rel; .z(3); }
        .bg-effect { .abs; .t(0); left: calc((100% - 1216px) / 2); .z(1); .wh(1216, 100%); .mh-c; .bg('@{do-event}/title-bg.svg'); .bg-h(100%); .bg-xy(50%, 50%); .no-repeat; }
      }
      .detail { .fs(16, 16px); .c(#cc4dc8); .mt(-8);
        > li {
          &:nth-of-type(1) { .mb(8);}

        }
      }
    }
    .contents-holder { .rel; .z(2); .max-w(335); .wh(100%); .mh-c; .p(0); .bgc(white); .br(20); .mt(-24);
      .notice { .wh(280, 88); .bg('@{do-event}/m/subtitle_bg.svg'); .tc; .fs(18, 24); .pt(20); .c(white); .mh-c; .t-y(-50%); .no-repeat; .rel;
        > em { .c(#1d399c); .bold;}
        &:before { .cnt; .contain('@{do-event}/m/star.svg'); .abs; .lt(10, 10); .wh(15); }
        &:after { .cnt; .contain('@{do-event}/m/star.svg'); .abs; .rt(10, 10); .wh(15);}
      }
      .items-holder { .w(100%); .mt(-24);
        .items { .p(0, 7); .flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start;
          > .item-holder { width: calc(100% / 2); .ani-base; .intro-up(1, {.ani-on;});
            &:nth-of-type(1) {
              .item-name { .c(#102ecd);}
              .badge {.bgc(#899bfe);}
              .select {
                .clicked-box { .-a(#afbcff, 6px);}
              }
            }
            &:nth-of-type(2) {
              .item-name { .c(#0067c6);}
              .badge {.bgc(#93caff);}
              .select {
                .clicked-box { .-a(#afd8ff, 6px);}
              }
            }
            &:nth-of-type(3) {
              .item-name { .c(#b40fad);}
              .badge {.bgc(#fe82fa);}
              .select {
                .clicked-box { .-a(#ffc2fe, 6px);}
              }
            }
            &:nth-of-type(4) {
              .item-name { .c(#5506a8);}
              .badge {.bgc(#b57bf2);}
              .select {
                .clicked-box { .-a(#debeff, 6px);}
              }
            }
            &:nth-of-type(5n) {
              .item-name { .c(#006e86);}
              .badge {.bgc(#72d3e9);}
              .select {
                .clicked-box { .-a(#b8eefa, 6px);}
              }
            }
            &:nth-of-type(5n+ 1) {
              .item-name { .c(#102ecd);}
              .badge {.bgc(#899bfe);}
              .select {
                .clicked-box { .-a(#afbcff, 6px);}
              }
            }
            &:nth-of-type(5n+2) {
              .item-name { .c(#0067c6);}
              .badge {.bgc(#93caff);}
              .select {
                .clicked-box { .-a(#afd8ff, 6px);}
              }
            }
            &:nth-of-type(5n+3) {
              .item-name { .c(#b40fad);}
              .badge {.bgc(#fe82fa);}
              .select {
                .clicked-box { .-a(#ffc2fe, 6px);}
              }
            }
            &:nth-of-type(5n+4) {
              .item-name { .c(#5506a8);}
              .badge { .bgc(#b57bf2);}
              .select {
                .clicked-box { .-a(#debeff, 6px);}
              }
            }
            &:nth-of-type(11) {
              .item-name { .ls(0);}
            }
          }
        }
      }
    }
    .btn { .wh(265, 48); .mt(40); .mb(70); .fs(24, 100%); .ls(-2); .p(0, 20);}
  }
  .bottom-graphics { .rel; .z(3);
    > img { .abs; .z(1); .w(48%); .min-w(361);
      &.left { .rb(60%, 0); .max-w(866); border-top-right-radius: 300px; }
      &.right { .lb(60%, 0); .max-w(881); border-top-left-radius: 300px; }
    }
  }
  @media (@tl-up) {
    .cherished-item-container { .pb(128); .bg('@{do-event}/title_elements_1920.svg'); .bg-w(789); .bg-xy(50%, 65);
      .rule { .mb(130);
        .main { .fs(36, 46px); .c(#1d399c); .mb(18); .ls(-2);
          &::before { .rt(85vw, -35); .wh(238, 227); }
          &::after { .lt(85vw, -48); .wh(254, 260); }
          br {
            &:nth-of-type(1) { .hide;}
          }
          .bg-effect { .w(1216); .bg-s(1216, 100%); }
        }
        .detail { .fs(24, 26px); .ls(-2); .c(#cc4dc8); }
      }
      .contents-holder { .max-w(908); .wh(100%); .mh-c; .bgc(white); .br(20);
        .notice { .wh(600, 64); .pt(20); .bg('@{do-event}/1024/subtitle_bg_1024.svg'); .fs(20);
          > em { .c(#1d399c); .bold;}
          > br { .hide;}
          &:before { .lt(20, 20); .wh(24);}
          &:after { .rt(20, 20); .wh(24);}
        }
        .items-holder { .mt(0);
          .items {
            > .item-holder { width: calc(100% / 4);
              .select {
                .clicked-box { border-width: 10px !important; }
              }
            }
          }

        }
      }

      .btn { .wh(480, 74); .mt(61); .mb(94); .fs(30, 100%); .ph(40);}
    }
  }
  @media (@ds-up) {
    .cherished-item-container { .tc; .pb(203); .bgc(#fbe2ff);
      .rule {
        .main {
          &::before, &::after { .block; }
          &::before { .lt(-20, -35); .wh(238, 227); }
          &::after { .rt(-20, -48); .wh(254, 260); }
        }
      }
      .contents-holder { .max-w(1260);
        .notice { .wh(908, 88); .pt(33); .bg('@{do-event}/1024/subtitle_1920.svg'); .fs(28);
          &:before { .wh(28); .lt(50, 30);}
          &:after { .wh(28); .rt(50, 30);}
        }
        .items-holder { .w(100%);
          .items { .p(0, 36); }
        }
      }
    }
  }
}

</style>
