const applyGifts = [
  {
    id: 0,
    itemId: '618DD2FCAE78A3038C62CD8B',
    contributor: '감스트',
    item: '샷건 키보드',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift0.jpg',
    size: '',
  },
  {
    id: 1,
    itemId: '618DD2FCAE78A3038C62CD8C',
    contributor: '감스트',
    item: '정수리향 모자',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift1.jpg',
    size: 'FREE-SIZE',
  },
  {
    id: 2,
    itemId: '618dd2fcae78a3038c62cd8d',
    contributor: '서새봄',
    item: '갬성 폴라로이드',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift2.jpg',
    size: '',
  },
  {
    id: 3,
    itemId: '618dd2fcae78a3038c62cd8e',
    contributor: '서새봄',
    item: '끼곰이 인형',
    count: '5',
    imageUrl: '/img/pages/loldocup/event/gift3.jpg',
    size: '',
  },
  {
    id: 4,
    itemId: '618DD2FCAE78A3038C62CD8F',
    contributor: '한동숙',
    item: '동수칸 X 너구리 DK유니폼',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift4.jpg',
    size: 'FREE-SIZE',
  },
  {
    id: 5,
    itemId: '619C5E5DB4B343076588C454',
    contributor: '부기드럼',
    item: '피땀눈물 스틱 & 반다나',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift5.jpg',
    size: '',
  },
  {
    id: 6,
    itemId: '619C5E5DB4B343076588C455',
    contributor: '김희철',
    item: '우주대스타 & 힙쟁이 모자',
    count: '2',
    imageUrl: '/img/pages/loldocup/event/gift6.jpg',
    size: 'FREE-SIZE',
  },
  {
    id: 7,
    itemId: '619C5E5DB4B343076588C456',
    contributor: '김희철',
    item: '아는형님 & 갓희님 모자',
    count: '2',
    imageUrl: '/img/pages/loldocup/event/gift7.jpg',
    size: 'FREE-SIZE',
  },
  {
    id: 8,
    itemId: '619C5E5DB4B343076588C457',
    contributor: '(여자)아이들 소연',
    item: '친필싸인 스케이드 보드',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift8.jpg',
    size: '',
  },
  {
    id: 9,
    itemId: '619C5E5DB4B343076588C458',
    contributor: '(여자)아이들 미연',
    item: '친필싸인 최애 자켓',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift9.jpg',
    size: 'S(90)',
  },
  {
    id: 10,
    itemId: '619C5E5DB4B343076588C459',
    contributor: 'T1 구마유시',
    item: '친필싸인 유니폼',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift10.jpg',
    size: 'XXL(110)',
  },
  {
    id: 11,
    itemId: '619C5E5DB4B343076588C45A',
    contributor: 'NS',
    item: '공식 유니폼',
    count: '3',
    imageUrl: '/img/pages/loldocup/event/gift11.jpg',
    size: 'L(100)',
  },
  {
    id: 12,
    itemId: '619C5E5DB4B343076588C45B',
    contributor: 'GEN 룰러',
    item: '친필싸인 유니폼',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift12.jpg',
    size: 'XXXL(115)',
  },
  {
    id: 13,
    itemId: '619C5E5DB4B343076588C45C',
    contributor: 'KT 에이밍',
    item: '친필싸인 유니폼',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift13.jpg',
    size: 'XXXL(115)',
  },
  {
    id: 14,
    itemId: '619C5E5DB4B343076588C45D',
    contributor: 'BRO 엄티',
    item: '친필싸인 마우스',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift14.jpg',
    size: '',
  },
  {
    id: 15,
    itemId: '619C5E5DB4B343076588C45E',
    contributor: 'DRX 킹겐',
    item: '친필싸인 굿즈 세트',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift15.jpg',
    size: 'L(100)',
  },
  {
    id: 16,
    itemId: '619C5E5DB4B343076588C45F',
    contributor: 'AF 기인',
    item: '친필싸인 게이밍 기어 세트',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift16.jpg',
    size: '',
  },
  {
    id: 17,
    itemId: '619C5E5DB4B343076588C460',
    contributor: 'DK 칸',
    item: '공식 유니폼',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift17.jpg',
    size: 'M(95)',
  },
  {
    id: 18,
    itemId: '619C5E5DB4B343076588C461',
    contributor: 'HLE',
    item: '공식 유니폼 및 자켓',
    count: '3',
    imageUrl: '/img/pages/loldocup/event/gift18.jpg',
    size: 'XL(105)',
  },
  {
    id: 19,
    itemId: '619C5E5DB4B343076588C462',
    contributor: 'LSB 크로코',
    item: '친필싸인 키보드',
    count: '1',
    imageUrl: '/img/pages/loldocup/event/gift19.jpg',
    size: '',
  },
];

export default applyGifts;
